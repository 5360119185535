<template>
  <div id="signly-video-loader" :class="itemClass">
    <div class="item">
      <fa
        icon="spinner"
        :size="iconSize"
        spin
      />

      <div class="loading-text">
        <span :style="{ 'font-size': fontSize }">{{ $t('videoLoader.loading') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Defaults from '@/libs/defaults'
import { video } from '@/components/mixins/video'

export default {
  name: 'SignlyVideoLoader',
  mixins: [video],
  props: {
    videoWidth: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemClass () {
      return this.loading ? 'loading' : 'not-loading'
    },
    iconSize () {
      return Defaults.video.iconSize[this.videoSize]
    },
    fontSize () {
      return Defaults.video.fontSize[this.videoSize]
    }
  }
}
</script>
